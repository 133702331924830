module.exports = [{
      plugin: require('/Users/ahmed.besbes/Documents/gatsby-blog/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"className":"gatsby-remark-autolink","maintainCase":true,"removeAccents":true},
    },{
      plugin: require('/Users/ahmed.besbes/Documents/gatsby-blog/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"showCaptions":true},
    },{
      plugin: require('/Users/ahmed.besbes/Documents/gatsby-blog/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/ahmed.besbes/Documents/gatsby-blog/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"tomato","showSpinner":true},
    },{
      plugin: require('/Users/ahmed.besbes/Documents/gatsby-blog/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/ahmed.besbes/Documents/gatsby-blog/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"GatsbyJS","short_name":"GatsbyJS","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone"},
    },{
      plugin: require('/Users/ahmed.besbes/Documents/gatsby-blog/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.ahmedbesbes.com"},
    },{
      plugin: require('/Users/ahmed.besbes/Documents/gatsby-blog/node_modules/gatsby-plugin-page-progress/gatsby-browser.js'),
      options: {"plugins":[],"includePaths":[{"regex":"^/blog"},{"regex":"^/case-studies"}],"excludePaths":["/"],"height":3,"prependToBody":false,"color":"#663399"},
    },{
      plugin: require('/Users/ahmed.besbes/Documents/gatsby-blog/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ahmed BESBES","short_name":"Ahmed BESBES","start_url":"/","background_color":"#6D83F2","theme_color":"#6D83F2","display":"standalone","icon":"src/static/logo.svg"},
    },{
      plugin: require('/Users/ahmed.besbes/Documents/gatsby-blog/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#6D83F2","showSpinner":false},
    },{
      plugin: require('/Users/ahmed.besbes/Documents/gatsby-blog/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-79148290-1","head":true},
    },{
      plugin: require('/Users/ahmed.besbes/Documents/gatsby-blog/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
